import React, {
    useEffect,
    useRef
} from "react";
import * as d3 from "d3";
import {formatting} from "../../Utils/Helpers"
const Legend = props => {
    
    const d3Container = useRef(null);

    useEffect(
        () => {
            const svg = d3.select(d3Container.current);
            let fakeData = [];
            let legend_width=220
            let rectWidth = 2;
            let mleft=10
            for (var i=0; i < legend_width; i+= rectWidth ) {
                fakeData.push(i);
            }
            let valuesA = [0,0.25,0.5,0.75,1];
            const scaleValues = d3.scaleLinear().domain([0, 1]).range([0, props.maxValue]);
            const scaleValues2 =  d3.scaleLinear().domain([0, props.maxValue]).range([0, 1]);
            let valuesB = valuesA.map(d=>scaleValues(d));
            const scaleValues3 = d3.scaleLinear().domain([0, legend_width]).range([0, 1]);
            svg.select('.bars').selectAll('rect')
            .data(fakeData)
            .join("rect")
            .attr("x", function(d) { return d + mleft; } )
            .attr("y", 10)
            .attr("height", 6)
            .attr("width", rectWidth)
            .attr("fill", (d, i) => {
                return d3.interpolateHsl(props.color[0], props.color[1])(scaleValues3(d))
            });

            svg.select('.values').selectAll('text')
            .data(valuesB)
            .join("text")
            .attr('text-anchor','middle')
            .attr('font-size','9px')
            .attr('fill','#9EBAD599')
            .attr("x", (d, i)=>legend_width*(i/(valuesB.length-1)  ) + mleft)
            .attr("y", 30)
            
            .text((d, i) => formatting(d,3));

            svg.select('.ticks').selectAll('line')
            .data(valuesB)
            .join("line")

            
            .attr("x1", (d, i)=>legend_width*(i/(valuesB.length-1)  ) + mleft)
            .attr("x2", (d, i)=>legend_width*(i/(valuesB.length-1)  ) + mleft)
            .attr("y1", 8)
            .attr("y2", 20)        
            .attr('stroke', '#9EBAD599')
            .attr('stroke-width', 1)
            .attr('stroke-opacity', 0.9)


            svg.select('.currValue').selectAll('text')
            .data(valuesB)
            .join("text")
            .attr('text-anchor','middle')
            .attr('font-size','9px')
            .attr("x", (d, i)=>legend_width*(i/(valuesB.length-1)  ) + mleft)
            .attr("y", 30)
            if (props.currValue)
                if(props.currValue.length>0)
                    svg.select('.currValue')
                        .transition().duration(100)
                        .attr("x", legend_width*scaleValues2(props.currValue[0].nsubs) + mleft)
                        .attr("y", 5)
                        .attr("width", 2)
                        .attr("height", 15)
        },
            [props.currValue,props.maxValue,props.color, props.height, props.width]
        );

    return (
        <svg 
            width={props.width}
            height={props.height}
            ref={d3Container} >
                <g className="bars" ></g>
                <g className="values"></g>
                <g className="ticks"></g>
                <rect className="currValue"></rect>
        </svg>
    )

};

export default Legend;