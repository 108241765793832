export const taxonomias =  [
    {
        title : 'Edad',
        code : 'A',
        values : [
            {label:'No identificado', value:'0'},
            {label:'0 a 17', value:'1'},
            {label:'18 a 24', value:'2'},
            {label:'25 a 34', value:'3'},
            {label:'35 a 44', value:'4'},
            {label:'45 a 54', value:'5'},
            {label:'55 a 64', value:'6'},
            {label:'65 a 74', value:'7'},
            {label:'75+', value:'8'}
        ]
    },
    {
        title : 'Género',
        code : 'B',
        values : [
            {label:'No identificado', value:'0'},
            {label:'Femenino', value:'1'},
            {label:'Masculino', value:'2'}
        ]
    },
    {
        title : 'NSE',
        code : 'C',
        values : [
            {label:'No identificado', value:'0'},
            {label:'A/B', value:'1'},
            {label:'C+', value:'2'},
            {label:'C', value:'3'},
            {label:'C-', value:'4'},
            {label:'D+', value:'5'},
            {label:'D', value:'6'},
            {label:'E', value:'7'}
        ]
    },
    {
        title : 'Residencia',
        code : 'D',
        values : [
            {label:'No identificado', value:'0'},
            {label:'Si', value:'1'},
            {label:'Mexicano otro Estado', value:'2'},
            {label:'Extranjero', value:'3'}
        ]
    }
    ,
    {
        title : 'Movilidad',
        code : 'E',
        values : [
            {label:'Desconocido', value:'0'},
            {label:'Nula', value:'1'},
            {label:'Baja', value:'2'},
            {label:'Media', value:'3'},
            {label:'Alta', value:'4'},
        ]
    }
];

export const audiencias=[
    {
        title : 'Audiencias',
        code : 'F',
        values:[
            {label:'Servicios de salud', value:'MI01'},
            {label:'Créditos bancarios', value:'MI02'},
            {label:'Redes sociales', value:'MI03'},
            {label:'Entretenimiento online', value:'MI04'},
            {label:'Apps de movilidad', value:'MI05'},
            {label:'Apps de servicios de conveniencia', value:'MI06'},
            {label:'Gamers', value:'MI07'},
            {label:'Usuarios de e-commerce', value:'MI08'},
            {label:'Interesados en productos bancarios', value:'MI09'},
            {label:'Turismo', value:'MI10'},
            {label:'Medicamentos farmacéuticos', value:'MI11'},
            {label:'Apuestas', value:'MI12'},
            {label:'Tecnología', value:'MI13'},
            {label:'Pet lovers', value:'MI14'},
            {label:'Bienes raíces', value:'MI15'},
            {label:'Temas sociales', value:'MI16'},
            {label:'Salud y bienestar', value:'MI17'},
            {label:'Deportes', value:'MI18'},
            {label:'Familia', value:'MI19'},
            {label:'Noticias de actualidad', value:'MI20'},
            {label:'Servicios gubernamentales', value:'MI21'},
            {label:'Educación', value:'MI22'},
            {label:'Citas', value:'MI23'},
            {label:'Búsqueda de empleo', value:'MI24'},
            {label:'Home Office', value:'MI25'},
            {label:'Emergencias', value:'MI26'},
            {label:'Transporte público', value:'MI27'},
            {label:'Auto privado', value:'MI28'},
            {label:'Bicicleta', value:'MI29'},
        ]
    }
];