import React, { useEffect, useRef, useState } from "react";
import {hour } from '../../Utils/Helpers';
import { set, uniq } from 'lodash';
import * as d3 from "d3";

const margin = {
    "top": 0,
    "right": 0,
    "bottom": 20,
    "left": 0
}

const Barchart = props => {
    const d3Container = useRef(null);
    const brush = useRef(null);


    const [brushed,setBrushed]=useState(false)


    useEffect(
        () => {
            let data = props.data
            const hoursList = uniq(data.map(d=>d.hora))
            const svg = d3.select(d3Container.current);
            data = hoursList.map(hora=>{
                return({
                    id:hora,
                    value: data.filter(d=>d.hora===hora).reduce((acc,val)=>acc+val.nsubs,0)
                })
            })

            const brushEnd = (event) => {
                if (!event.selection) {
                    if(props.play){
                        props.updatePlay(false) 
                        props.stop()
                     }
                     svg.select(".bars").selectAll("rect").transition().duration(100)
                     .style("fill", '#9EBAD599')
                    
 
                    props.update([0,23]);
                    


                    
                }
                else{ 
                if (event.sourceEvent && (event.sourceEvent.type==="mousemove" || event.sourceEvent.type==="mouseup" )) {
                 

                    if(props.play){
                       props.updatePlay(false) 
                       props.stop()
                    }
                    
                    const [x1, x2] = event.selection;
                    let range = [Math.floor((x1)/xScale.bandwidth()),Math.floor((x2)/xScale.bandwidth()-1)];
     
                    props.update(range)
                    
 

                    svg.select(".bars").selectAll("rect").transition().duration(100)
                    .style("fill", (d, i) =>  (i>=range[0] && i<=range[1]) ?'#FFFFFFDD':'#9EBAD599')
                   


                    if (range[1]===24)
                        range[1]=23
                    range= [range[0]*xScale.bandwidth(),(range[1]+1)*xScale.bandwidth()]
                    svg.select(".brush").call(brush.current.move,range)
                   
                }else{

                    const [x1, x2] = event.selection;
                    let range = [Math.floor((x1)/xScale.bandwidth()),Math.floor((x2)/xScale.bandwidth()-1)];
  

                    svg.select(".bars").selectAll("rect").transition().duration(100)
                    .style("fill", (d, i) =>  (i>=range[0] && i<=range[1]) ?'#FFFFFFDD':'#9EBAD599')

                }



                    
                }
    
                
            };

            brush.current = d3.brushX()
            .extent([[0,margin.top],[props.width, props.height - margin.bottom]])
            .on("brush end", brushEnd);

            svg.select(".brush").call(brush.current);

            const yMax = d3.max(data, d => d.value);
            const xDomain = hour;
            const xScale = d3.scaleBand()
                .domain( xDomain )
                .range([ 0, props.width])

            const yScale = d3.scaleLinear()
                .domain([ 0, yMax ])
                .range([ props.height - margin.bottom, margin.top ]);

            svg.select('.axis')
                .selectAll('text')
                .data( data, d => d.id )
                .join('text')
                .attr('class', 'value')
                .text((d,i) => (i%2==0)?d.id:"")
                .style('fill', '#999')
                .style('font-size', '10px')
                .style('font-weight', 400)
                .style("text-anchor", "middle")
                .attr("x",d=>margin.left+  xScale(d.id)+ xScale.bandwidth()/2)
                .attr("y",yScale(0)+13)
 
            svg.select('.bars').selectAll('rect').data( data, d => d.id )
                .join(
                    enter => enter.append('rect')
                        .attr('class', 'bar')
                        .attr('x', d => xScale(d.id) + .5)
                        .attr('y', d => yScale(d.value))
                        .attr('width', xScale.bandwidth()-1)
                        .attr('height', 0)
                        .attr('y', yScale(0))
                        .style('fill', '#9EBAD599')
                        .call(enter => enter.transition(800)
                            .attr('height', d => yScale(0) - yScale(+d.value))
                            .attr('y', d => yScale(+d.value))
                        ),
                    update => update

                        .call(update=>update.transition(800)
                            .attr('height', d => yScale(0) - yScale(+d.value))
                            .attr('y', d => yScale(+d.value))
                            
                    ),
                    exit => exit
                    .call(exit=>exit.remove())              
                )
        },
            [props.data,props.play,props.stop, props.height, props.width]
        );


        useEffect(
            () => {
                const svg = d3.select(d3Container.current);

         
                    if(props.time[0]===0 && props.time[1]===23 && props.cleared){
                        //svg.select(".brush").selectAll("rect").style("display","none")
                        svg.select(".bars").selectAll("rect").transition().duration(100)
                        .style("fill", '#9EBAD599')
                        svg.select(".brush").call(brush.current.move,[0,0])
                        props.updateCleared(false)
                    }
                
                    

                if (props.play){



                    
                    const xDomain = hour;
                    const xScale = d3.scaleBand()
                        .domain( xDomain )
                        .range([ 0, props.width])
                    let range= [props.time[0]*xScale.bandwidth(),(props.time[1]+1)*xScale.bandwidth()]


                    svg.select(".brush").call(brush.current.move,range)

                }

               






            },
                [props.time,props.play,brushed]
            );

    return (
        <svg 
            width={props.width}
            height={props.height}
            ref={d3Container} >
                <g className="bars" ref={d3Container}></g>
                <g className="labels"></g>
                <g className="values"></g>
                <g className="axis"></g>
                <g className="brush" transform={"translate(0,0)"} />
        </svg>
    )
};
export default Barchart;