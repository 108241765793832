import jwtDecode from "jwt-decode";
import moment from 'moment';
import * as d3 from "d3";

export const validateToken = (token) => {
    if (!token) {
        return null;
    }
    try {
        const decodedJwt = jwtDecode(token);

        //console.log(decodedJwt.exp)
        if ((decodedJwt.exp + 500000) >= Date.now() / 1000) 
            return decodedJwt;
        else return null
    } catch (e) {
        return null;
    }
};

export const hexToRgb = (hex) =>{
    // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
    const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
    hex = hex.replace(shorthandRegex, function(m, r, g, b) {
      return r + r + g + g + b + b;
    });
  
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result ? {
      r: parseInt(result[1], 16),
      g: parseInt(result[2], 16),
      b: parseInt(result[3], 16)
    } : null;
  }

export const rgbToArray = (rgb) =>{
    let transform= rgb.replace('rgb(','').replace(')','')
    transform = (transform.split(', '))
    return {
        r: +transform[0],
        g: +transform[1],
        b: +transform[2]
    }
}

export const flatten = (arr) => [].concat.apply([], arr);

export const product = (sets) =>
    sets.reduce((acc, set) =>
        flatten(acc.map(x => set.map(y =>  x+ y ))),
        [[]]);
export const getDates = (startDate, stopDate) =>{
    const dateArray = [];
    let currentDate = moment(startDate);
    const momentStopDate = moment(stopDate);
    while (currentDate.isBefore(momentStopDate)) {
        dateArray.push(currentDate.format("YYYYMMDD"));
        currentDate = currentDate.add(1, 'days')
    }
    dateArray.push(momentStopDate.format("YYYYMMDD"));
    return dateArray;
}
export const hour = ["00","01","02","03","04","05","06","07","08","09","10","11","12","13","14","15","16","17","18","19","20","21","22","23"]

export const formatting = (num,dec) => {
    if (isNaN(num)) return ("")
    const newNum =
    (num >= 1000000000)? {value:num/1000000000, sign:'B'}:
                    (num >= 1000000)? {value:num/1000000, sign:'M'}:
                    (num >= 1000)? {value:num/1000, sign:'k'} : {value:num, sign:''}
    if (num>=1)
    return (d3.format("." + dec +"s")(newNum.value) + newNum.sign )
    else
    return (d3.format(".2f")(newNum.value) )

}


