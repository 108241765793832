import React, { useState, useEffect, useRef } from 'react';
import DeckGL from '@deck.gl/react';
import { GeoJsonLayer} from '@deck.gl/layers';
import { StaticMap } from 'react-map-gl';
import { Auth, Analytics } from 'aws-amplify';
import { AUTH_USER_TOKEN_KEY, MAPBOX_ACCESS_TOKEN, MAPBOX_STYLE_1,MAPBOX_STYLE_2,MAPBOX_STYLE_3,URL1,URL2 } from '../../Utils/constants';
import { taxonomias, audiencias } from '../../Utils/taxo';
import { validateToken, hexToRgb, getDates, product, hour, rgbToArray, formatting } from '../../Utils/Helpers';
import { Button, Collapse, Select, DatePicker, Divider, Slider, Spin, Modal, Space, Radio, notification, Input, Table, List, Popover, Tag, Popconfirm} from 'antd';
import { set, uniq,xor } from 'lodash';
import moment from 'moment';
import * as d3 from 'd3';
import BarChart from './BarChart';
import Histogram from './Histogram';
import Legend from './Legend';
import  './index.css';
import { CloseCircleFilled,DeleteFilled,InfoCircleFilled, PlusCircleFilled } from '@ant-design/icons';


const axios = require('axios');

const { RangePicker } = DatePicker;
const { Panel } = Collapse;
const { Option } = Select;

const controller = true;

const viewState = {
    longitude:-100.5,
    latitude: 20.8,
    zoom: 5.8,
    pitch: 0,
    bearing: 0,
    minZoom: 5,
    maxZoom: 16,
}

const calculations = [
    {value:"mean", label:"Promedio"},
    {value:"max", label:"Máximo"},
    {value:"min", label:"Mínimo"},
]

const estados = {
    "guanajuato":{label:"Guanajuato"},
    "queretaro":{label:"Quéretaro"},
    "nuevo_leon":{label:"Nuevo León"},
    "nuevoleon":{label:"Nuevo León"},
    "jalisco":{label:"Jalisco"},
}

const DashboardContainer = (props) => {

    const [layer, setLayer] = useState([])
    const [email, setEmail] = useState('')
    const [geoData, setGeoData] = useState([])
    const [geoDataList, setGeoDataList] = useState([])
    const [data, setData] = useState([])
    const [group, setGroup] = useState("")
    const [groupList, setGroupList] = useState([])

    const [bookmarksList, setBookmarks] = useState([])
    const [newBookmarkTitle, setNewBookmarkTitle] = useState("")

    const [calculation, setCalculation] = useState("mean")
    const [dates, setDates] = useState(getDates('',''))
    const [estadosDates, setEstadosDates] = useState(['',''])

    const [postalCodes, setPostalCodes] = useState([])
    const [intervalPlay, setIntervalPlay] = useState(500)
    const [taxonomiaSelection, setTaxonomiaSelection] = useState({A:[],B:[],C:[],D:[],E:[],F:[]})
    const [time, setTime] = useState([0,23])
    const [kpis, setKpis] = useState({})
    const [play,setPlay] = useState(false)
    const [hoverInfo,setHoverInfo] = useState()
    const [keyPressed, setKeyPressed] = useState()
    const [cleared, setCleared] = useState(false)
    const [linearPostalCodes, setLinearPostalCodes] = useState("")

    const changeSearch = (e) =>{        
        const codes= geoDataList.filter(d=>e.includes(d.county))
        setLinearPostalCodes(codes.map(d=>d.d_cp))
    }

    const [aggPostalCodes,setAggPostalCodes]= useState()
    const [maxValue, setMaxValue] = useState(0)

    const [mapStyle, setMapStyle] = useState(MAPBOX_STYLE_1)

    const color1 = useRef("#F4E72F");
    const color2 = useRef("#003EB3");
    const [color, setColor] = useState([color1.current,color2.current])

    const [opacity,setOpacity]=useState(50)

    const [querying, setQuerying] = useState(false)
    const [loadingBookmarks, setLoadingBookmarks] = useState(false)
    const [loadingPDF, setLoadingPDF] = useState(false)

    const handleLogout = async () => {
        const { history } = props;
        try {
          await Auth.signOut({ global: true }).then(() => {
            localStorage.removeItem(AUTH_USER_TOKEN_KEY);
            history.push('/login');
          });
        } catch (err) {
        }
    };

    const changeTaxonomia = (field,val) => {
        let test = {...taxonomiaSelection}
        test[field] = val
        setTaxonomiaSelection(test)
    }

    const changeDate = (date,dateString)=>setDates(getDates(dateString[0],dateString[1]))

    const calculate = (taxonomiaSelection)=>{
        setQuerying(true)
        //dates
        const paramDates = dates.reduce((acc,val)=> acc===''?acc+val:acc+','+val,'')
        //taxonomias
        const taxoCompletion = Object.keys(taxonomiaSelection).map(d=>taxonomiaSelection[d].length===0?d==='F'?['TTTT']:['T']:taxonomiaSelection[d])
        const productTaxo = product(taxoCompletion)
        const paramTaxo = productTaxo.reduce((acc,val)=> acc===''?acc+val:acc+','+val,'')
        const checkUserAuth = validateToken(localStorage.getItem(AUTH_USER_TOKEN_KEY));
        if(!checkUserAuth){             
            const {history} = props;
            history.push("/login")
        }
        const {client_id,username} = checkUserAuth
        const bearer = 'Bearer ' + localStorage.getItem('CognitoIdentityServiceProvider.' + client_id + '.' + username + '.idToken')
        const callBody='/apiv2/aggregate_audiences?state_id=' + group + '&dates='+paramDates+'&audiences='+paramTaxo+'&agg_func='+calculation

        fetch(callBody, {
            method: 'GET',
            credentials: 'include',
            headers: {
                'Authorization': bearer,
                'Connection': 'keep-alive',
            },
        })
        .then(res => res.json())
        .then(data => {
            setData(data)
            setQuerying(false)
            //Record
            Analytics.record({
                name: 'calculate',
                attributes: { user: email ,  calculation:calculation, state: group, dates: dates, audiences: "taxonomiaSelection" }
            });
        }).catch(error=>{
            notification.error({
                message: 'No hay datos!',
                description: 'Intenta otra audiencia',
                placement: 'topLeft',
                duration: 1.5
              })

            setQuerying(false)}
            )   
    }

    const deleteBookmark = (dateBookmark)=>{
        setLoadingBookmarks(true)
        const checkUserAuth = validateToken(localStorage.getItem(AUTH_USER_TOKEN_KEY));
        if(!checkUserAuth){             
            const {history} = props;
            history.push("/login")
        }
        let newBookmarksList = bookmarksList.filter(d=>d.date!==dateBookmark)
        const {client_id,username} = checkUserAuth
        const bearer = 'Bearer ' + localStorage.getItem('CognitoIdentityServiceProvider.' + client_id + '.' + username + '.idToken')
        const postBody="/apiv2/bookmarks?bookmarks=" +  encodeURIComponent(JSON.stringify(newBookmarksList))

        fetch(postBody, {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Authorization': bearer,
                'Connection': 'keep-alive',
            }
        })
        .then(res => res.json())
        .then(data => {
            readBookmark()
            //setLoadingBookmarks(false)

        }).catch(error=>{
            setBookmarks([])
            setLoadingBookmarks(false)
        })   
    }

    const writeBookmark = ()=>{
        setLoadingBookmarks(true)
        //taxonomias

        const checkUserAuth = validateToken(localStorage.getItem(AUTH_USER_TOKEN_KEY));
        if(!checkUserAuth){             
            const {history} = props;
            history.push("/login")
        }

        let newBookmarksList = [...bookmarksList]

        const newBookmark = {
            "title": newBookmarkTitle,
            "date":(Date.parse(new Date())),
            "content": taxonomiaSelection
        }

        newBookmarksList.unshift(newBookmark)


        const {client_id,username} = checkUserAuth
        const bearer = 'Bearer ' + localStorage.getItem('CognitoIdentityServiceProvider.' + client_id + '.' + username + '.idToken')
        const postBody="/apiv2/bookmarks?bookmarks=" +  encodeURIComponent(JSON.stringify(newBookmarksList))

        fetch(postBody, {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Authorization': bearer,
                'Connection': 'keep-alive',
            }
        })
        .then(res => res.json())
        .then(data => {
            readBookmark()            
            //setLoadingBookmarks(false)

        }).catch(error=>{
            setLoadingBookmarks(false)
        })   
    }

    const readBookmark = ()=>{
        setLoadingBookmarks(true)
        //taxonomias
        const checkUserAuth = validateToken(localStorage.getItem(AUTH_USER_TOKEN_KEY));
        if(!checkUserAuth){             
            const {history} = props;
            history.push("/login")
        }
        const {client_id,username} = checkUserAuth
        const bearer = 'Bearer ' + localStorage.getItem('CognitoIdentityServiceProvider.' + client_id + '.' + username + '.idToken')
        const postBody="/apiv2/bookmarks"

        fetch(postBody, {
            method: 'GET',
            credentials: 'include',
            headers: {
                'Authorization': bearer,
                'Connection': 'keep-alive',
            }
        })
        .then(res => res.json())
        .then(data => {
            setBookmarks(data)
            setLoadingBookmarks(false)

        }).catch(error=>{

            setLoadingBookmarks(false)
        })   
    }

    const exportPDF = ()=>{

        setLoadingPDF(true)
        const checkUserAuth = validateToken(localStorage.getItem(AUTH_USER_TOKEN_KEY));
        if(!checkUserAuth){             
            const {history} = props;
            history.push("/login")
        }
        const {client_id,username} = checkUserAuth
 
        const taxoCompletion = Object.keys(taxonomiaSelection).map(d=>taxonomiaSelection[d].length===0?d==='F'?['TTTT']:['T']:taxonomiaSelection[d])
        const productTaxo = product(taxoCompletion)
        const paramDates = dates.reduce((acc,val)=> acc===''?acc+val:acc+','+val,'')
        const paramTaxo = productTaxo.reduce((acc,val)=> acc===''?acc+val:acc+','+val,'')
        //taxonomias
        const paramPostalCodes = postalCodes.reduce((acc,val)=> acc===''?acc+val:acc+','+val,'')
        const bearer = 'Bearer ' + localStorage.getItem('CognitoIdentityServiceProvider.' + client_id + '.' + username + '.idToken')
        const getBody='/apiv2/get_pdf?state_id='+group+'&dates='+paramDates+'&audiences='+paramTaxo+'&cod_post=' + paramPostalCodes

        notification.open({
            message: 'Reporte PDF',
            description: 'Estamos generando el reporte en PDF y tomará unos segundos',
            placement: 'topRight',
            duration: 3
          })

        axios(getBody, {
            method: 'GET',
            credentials: 'include',
            timeout: 1000000,
            headers: {
                'Authorization': bearer,
                //'Connection': 'keep-alive',
            },
            responseType:'blob',
        })
        .then(res => {
 
            const file= new Blob(
                [res.data],
                {type:'application/pdf'});       
            const fileURL=URL.createObjectURL(file)
            const a = document.createElement("a");
            document.body.appendChild(a);
            a.style = "display: none";
                a.href = fileURL;
                a.download = group + "_export.pdf";
                a.click();
                window.URL.revokeObjectURL(fileURL);
                setLoadingPDF(false)

        }).catch(error=>{
 
            notification.error({
                message: 'Error',
                description: 'No se ha podido generar el report',
                placement: 'topRight',
                duration: 3
              })
            setLoadingPDF(false)
        })   
    }
    const disabledDate = (current) =>  current < moment(estadosDates[0]).startOf('day') || current > moment(estadosDates[1]).endOf('day')

    useEffect(() => {
      
        window.addEventListener('keydown', function(event) {
            const key = event.key; // "a", "1", "Shift", etc.
                if(key==="Shift")setKeyPressed(key)
        });

        window.addEventListener('keyup', function(event) {
            const key = event.key; // "a", "1", "Shift", etc.
            if(key==="Shift") setKeyPressed()
        });
        const checkUserAuth = validateToken(localStorage.getItem(AUTH_USER_TOKEN_KEY));
        const paramDates = dates.reduce((acc,val)=> acc===''?acc+val:acc+','+val,'')
        const taxoCompletion = Object.keys(taxonomiaSelection).map(d=>taxonomiaSelection[d].length===0?d==='F'?['TTTT']:['T']:taxonomiaSelection[d])
        const productTaxo = product(taxoCompletion)
        const paramTaxo = productTaxo.reduce((acc,val)=> acc===''?acc+val:acc+','+val,'')

        if (checkUserAuth) {

            const {client_id,username} = checkUserAuth
            const bearer = 'Bearer ' + localStorage.getItem('CognitoIdentityServiceProvider.' + client_id + '.' + username + '.idToken')
            
            const getBody='/apiv2/bookmarks'

            fetch(getBody, {
                method: 'GET',
                credentials: 'include',
                headers: {
                    'Authorization': bearer,
                    'Connection': 'keep-alive',
                }
            })
            .then(res => res.json())
            .then(data => {
            }).catch(error=>
                setBookmarks([])
            )   
           
            const groups = checkUserAuth["cognito:groups"]

            if(groups){
                setEmail(username)
                setGroupList(groups)
                setGroup(groups[0])
                setDates(getDates(estadosDates[1],estadosDates[1]))
                readBookmark()
            }
            else{
                notification.error({
                    message: 'Error',
                    description: 'No pertenece a ningun grupo',
                    placement: 'topLeft',
                    duration: 3
                  })

                const {history} = props;
                history.push("/login")

            }

 
        } else {
            const {history} = props;
            history.push("/login")
        }
    }, [])

    useEffect(() => {
        if(group){
            setQuerying(true)
            const checkUserAuth = validateToken(localStorage.getItem(AUTH_USER_TOKEN_KEY));
            const taxoCompletion = Object.keys(taxonomiaSelection).map(d=>taxonomiaSelection[d].length===0?d==='F'?['TTTT']:['T']:taxonomiaSelection[d])
            const productTaxo = product(taxoCompletion)
            const paramTaxo = productTaxo.reduce((acc,val)=> acc===''?acc+val:acc+','+val,'')

            if (checkUserAuth) {
                const {client_id,username} = checkUserAuth
                const bearer = 'Bearer ' + localStorage.getItem('CognitoIdentityServiceProvider.' + client_id + '.' + username + '.idToken')

                const datesBody = '/apiv2/get_dates?state_id=' + group 

                fetch(datesBody, {
                    method: 'GET',
                    credentials: 'include',
                    headers: {
                        'Authorization': bearer,
                        'Connection': 'keep-alive',
                    },
                })
                .then(res => res.json())
                .then(test => {
                    const dateMax=d3.max(test,d=>d.date).toString()
                    const dateMin=d3.min(test,d=>d.date).toString()
                    setDates(getDates(dateMax,dateMax))
                    setEstadosDates([dateMin,dateMax])
                    const paramDates = getDates(dateMax,dateMax).reduce((acc,val)=> acc===''?acc+val:acc+','+val,'')
                    const callBody = '/apiv2/aggregate_audiences?state_id=' + group + '&dates='+paramDates+'&audiences='+paramTaxo+'&agg_func='+calculation
                    fetch('/data/'+ group +'.geojson')
                        .then(res => res.json())     
                        .then(geo => {
                            fetch(callBody, {
                                method: 'GET',
                                credentials: 'include',
                                headers: {
                                    'Authorization': bearer,
                                    'Connection': 'keep-alive',
                                },
                            })
                            .then(res => res.json())
                            .then(data => {
                                setGeoData(geo)
                                setGeoDataList(geo.features.map(e=>e.properties))
                                setData(data)
                                setQuerying(false)
                            })
                        })
                    })
                } else {
                    const {history} = props;
                    history.push("/login")
            }
        }
    }, [group])

    useEffect(() => {
        const codesListTotal = uniq(data.map(d=>d.codigo_postal))
        
        const dataSelected_wo_code = data.filter(d=> d.hora>=hour[time[0]] && d.hora<=hour[time[1]])
        const dataSelected = data.filter(d=> (postalCodes.length>0?postalCodes.includes(d.codigo_postal):true) && d.hora>=hour[time[0]] && d.hora<=hour[time[1]])
        const codesList = uniq(dataSelected.map(d=>d.codigo_postal))
        
        let aggCodes = {}
        
        codesListTotal.forEach(code=>{
            //aggCodes[code]= dataSelected_wo_code.filter(d=>d.codigo_postal===code).reduce((acc,val)=>acc+val.nsubs,0)  
            const codeData = dataSelected_wo_code.filter(d=>d.codigo_postal===code)
            aggCodes[code]= {
                sum:d3.sum(codeData,d=>d.nsubs),
                mean:d3.sum(codeData,d=>d.nsubs)/(time[1]-time[0]+1),
                max:d3.max(codeData,d=>d.nsubs),
                min:d3.min(codeData,d=>d.nsubs)
            }           
        })

        setAggPostalCodes(aggCodes)
        
        const scaleColor = d3.scaleLinear().domain([0, d3.max(codesList,code => aggCodes[code].mean)]).range([0, 1]);
        setMaxValue(d3.max(codesList,code => aggCodes[code].mean))
        const geojsonLayer = new GeoJsonLayer({
            data: geoData,
            opacity: 1,
            stroked: true,
            filled: true,
            extruded: false,
            wireframe: false,
            lineWidthMaxPixels:1.5,

            getFillColor: f =>{
                let returnColor = [100,100,150,opacity]
                const f_code = f.properties.d_cp

                if (codesListTotal.includes(f_code)){
                    returnColor = [250,150,150,opacity]
                    if (codesList.includes(f_code)){
                        if (aggCodes[f_code].mean!==undefined){
                            returnColor = rgbToArray(d3.interpolateHsl(color[0], color[1])(scaleColor(aggCodes[f_code].mean)))
                            returnColor = [returnColor.r,returnColor.g,returnColor.b,opacity*2.55]
                        }
                }

                }
                return returnColor
            },
            getLineColor: f =>{
                let lineW = [0,0,0,150]

                return lineW
            },
            getLineWidth: f =>{
                let lineW = 10
                const f_code=f.properties.d_cp
                if(linearPostalCodes.includes(f_code))
                    lineW = 500
                return lineW
            },
            pickable:true,
            autoHighlight:true,
            
            onHover: f => {        
                
                
                if(f.object){
                    setHoverInfo({
                        x:f.x,
                        y:f.y,
                        codigo_postal:f.object.properties.d_cp,
                        asentamientos:f.object.properties.asentamientos,
                        county:f.object.properties.county,
                    })
                }
                else{
                    setHoverInfo()  
                } 
            },
            onClick:f=>{


                if(keyPressed==="Shift"){
                    setPostalCodes(xor(postalCodes,[f.object.properties.d_cp]))

                }
                else setPostalCodes([f.object.properties.d_cp])
            },
            updateTriggers: {
                getFillColor: f =>{
                    let returnColor = [150,150,150,opacity]
                    const f_code=f.properties.d_cp
                    if (aggCodes[f_code].mean!==undefined){
                        returnColor = hexToRgb(d3.interpolateViridis(scaleColor(aggCodes[f_code].mean)))
                        returnColor = [returnColor.r,returnColor.g,returnColor.b,opacity*2.55]
                    }
                    return returnColor
                },

                getLineWidth: f =>{
                    let lineW = 10
                    const f_code=f.properties.d_cp
                    if(linearPostalCodes.includes(f_code))
                        lineW = 500
                    return lineW
                },
            },
 

        });
        setLayer(geojsonLayer)
        setKpis(kpiCalculations(data,postalCodes,time))
    }, [data, time, postalCodes, opacity, color, linearPostalCodes])


    const kpiCalculations = (data,postalCodes,time) => {
        const total = d3.sum(data, d=>d.nsubs)
        const total_cp = uniq(data.map(d=>d.codigo_postal)).length
        const selection = d3.sum(data.filter(d=> (postalCodes.length>0?postalCodes.includes(d.codigo_postal):true) && d.hora>=hour[time[0]] && d.hora<=hour[time[1]]),x=>x.nsubs)
        const selection_hora = time[1]-time[0] + 1
        const selection_cp = (postalCodes.length>0?postalCodes.length:total_cp)
        const total_total_hora= total/24
        const total_total_cp = total/total_cp
        const total_total_hora_total_cp = total/(total_cp*24)
        const selection_selection_hora = selection/selection_hora
        const selection_selection_cp = selection/selection_cp
        const selection_selection_hora_selection_cp = selection/(selection_cp*selection_hora)
        const share_selection = (selection/total)*100
        const share_hora_cp = ((selection_selection_hora_selection_cp/total_total_hora_total_cp)-1)*100
        const share_hora = ((selection_selection_hora/total_total_hora)-1)*100
        const share_cp = ((selection_selection_cp/total_total_cp)-1)*100
        return ({
          total,
          total_cp,
          total_total_hora,
          total_total_cp,
          total_total_hora_total_cp,
          selection,
          selection_hora,
          selection_cp,
          selection_selection_hora,
          selection_selection_cp,
          selection_selection_hora_selection_cp,
          share_selection,
          share_hora_cp,
          share_hora,
          share_cp
        })
    }

   
    const startAnimation = useRef(null)

    useEffect(()=>{
        if (play) {
            startAnimation.current = 
            setTimeout(()=>                 
            {
                    let range= time;
                    range=[time[0]+1,time[1]+1]
                    if(range[1]===24)
                        range= [0,range[1]-range[0]]
                    setTime(range)
            },intervalPlay)
        } 

    },[play,time])


    return (
    <div>

        <DeckGL
            initialViewState={viewState}
            controller={controller}
            layers = {[layer]}
            style={{position:"fixed"}}
        >
            <StaticMap
                mapboxApiAccessToken={MAPBOX_ACCESS_TOKEN}
                mapStyle={mapStyle}
            />
            {hoverInfo?
                <div>
                    <div style={{margin:'0px', padding:'16px', maxWidth:350, backgroundColor:'#000000C0', position: 'absolute', zIndex: 1, pointerEvents: 'none', left: hoverInfo.x, top: hoverInfo.y}}>
                        <div style={{fontSize:'12px', fontWeight:700}}>Código Postal</div>
                        <div style={{fontSize:'18px', fontWeight:700}}>{hoverInfo.codigo_postal}</div>
                        {aggPostalCodes[hoverInfo.codigo_postal]?
                        <div>
                            <br></br>
                            <div style={{fontSize:'12px', fontWeight:700}}>
                                Promedio de suscriptores:
                            </div>
                            <div style={{fontSize:'24px', fontWeight:700}}>{formatting(aggPostalCodes[hoverInfo.codigo_postal].mean,4)}</div>
                            <br></br>
                            <div>
                                <div style={{width:'50%',  display:'inline-block'}}>
                                    <div style={{fontSize:'12px', fontWeight:700}}>
                                        Máx. suscriptores
                                    </div>
                                    <div style={{fontSize:'14px', fontWeight:700}}>{formatting(aggPostalCodes[hoverInfo.codigo_postal].max,4)}</div>
                                </div>
                                <div  style={  {width:'50%', display:'inline-block'}}>
                                    <div style={{fontSize:'12px', fontWeight:700}}>
                                        Min. suscriptores
                                    </div>
                                    <div style={{fontSize:'14px',display:'inline-block', fontWeight:700}}>{formatting(aggPostalCodes[hoverInfo.codigo_postal].min,4)}</div>
                                </div>
                            </div>
                        </div>:null}
                        <Divider></Divider>
                        <div style={{fontSize:'12px', fontWeight:700}}>Municipio</div>
                        <div>{hoverInfo.county}</div>
                        <br></br>
                        <div style={{fontSize:'12px', fontWeight:700}}>Asentamientos</div>
                        <div style={{fontSize:'10px', fontWeight:300}}>{hoverInfo.asentamientos}</div>
                        
 
                    </div>
                </div>
            :null}
        </DeckGL>


        <div className={'DivScrollLeft'}>
            <div>
                
                <h2 style={{margin:'8px', padding:'8px'}}>MIA</h2>
                    <div style={{margin:'16px', padding:'0px',}}>
                        {(groupList.length>1)?
                        <div>
                            <div style={{fontSize:"12px", }}>Estado</div>
                            <Select
                                style={{margin:"0px",width: 230,fontSize:"12px"}}
                                value={group}
                                placeholder={"Todos"}
                                onChange={e => {
                                    //setDates(getDates(estadosDates[1],estadosDates[1]))
                                    setTaxonomiaSelection({A:[],B:[],C:[],D:[],E:[],F:[]});
                                    setPostalCodes([])
                                    setGroup(e)}}
                                bordered={true}>
                                    {groupList.map(d=><Option key={d} value={d}>{estados[d].label}</Option>)}
                            </Select>
                        </div>
                        :null}
                    </div>
                    <Collapse bordered={false} defaultActiveKey={['1']} ghost>
                        <Panel 
                        header="Fechas" key="1" 
                        style={{ fontSize:"14px"}}>
                            <div className='insideCollapse'>
                            <RangePicker
                            allowClear={false}
                                format="YYYYMMDD"
                                onChange={changeDate}
                                disabledDate={disabledDate}
                                bordered={false}
                                value={group?[moment(dates[0]),moment(dates[dates.length-1])]:null}
                            />
                            </div>
                        </Panel>
                        <Panel 
                            header="Atributos Socio-demográficos" key="2" 
                            style={{ fontSize:"14px"}}>
                            <div className='insideCollapse'>
                            {taxonomias.map(taxo=>
                                <div style={{margin:'4px', padding:'0px',}}>
                                    <div style={{fontSize:"12px", }}>{taxo.title}: </div>
                                    <Select
                                        mode="multiple"
                                        allowClear
                                        style={{margin:"0px",width: 230,fontSize:"12px"}}
                                        value={taxonomiaSelection[taxo.code]}
                                        placeholder={"Todos"}
                                        onChange={e => changeTaxonomia(taxo.code,e)}
                                        bordered={false}>
                                            {taxo.values.map(d=><Option key={d.value} value={d.value}>{d.label}</Option>)}
                                    </Select>
                                </div>
                            )}
                            </div>
                        </Panel>
                        <Panel 
                            header="Audiencias" key="3" 
                            style={{ fontSize:"14px"}}>
                                <div className='insideCollapse'>
                                {audiencias.map(taxo=>
                                    <div style={{margin:'4px', padding:'0px',}}>
                                        <div style={{fontSize:"12px", }}>{taxo.title}: </div>
                                        <Select
                                            mode="multiple"
                                            allowClear
                                            style={{margin:"0px",width: 230,fontSize:"12px"}}
                                            value={taxonomiaSelection[taxo.code]}
                                            placeholder={"Todos"}
                                            onChange={e => changeTaxonomia(taxo.code,e)}
                                            bordered={false}>
                                                {taxo.values.map(d=><Option key={d.value} value={d.value}>{d.label}</Option>)}
                                        </Select>
                                    </div>
                                )}
                                </div>
                        </Panel>
                    </Collapse>
                    <div style={{ margin:'16px',textAlign:'right'}}>
                        {d3.sum(Object.keys(taxonomiaSelection),d=>taxonomiaSelection[d].length)===0?null:
                        <Button onClick={()=>setTaxonomiaSelection({A:[],B:[],C:[],D:[],E:[],F:[]})}>Limpiar selección</Button>}
                    </div>
                    <div style={{ margin:'16px',textAlign:'right'}}>    
                        <Space>
                            <Select
                                style={{width:100, margin:"0px", fontSize:"12px"}}
                                value={calculation}
                                onChange={setCalculation}
                                bordered={false}>
                                {
                                    calculations.map(d=><Option value={d.value}>{d.label}</Option>)
                                }
                            </Select>
                            <Button onClick={()=>calculate(taxonomiaSelection)} loading={querying}>Calcular</Button>
                        </Space>
                    </div>
                    <Divider style={{ margin:'8px'}}></Divider>
                    <Collapse bordered={false} defaultActiveKey={['1']} ghost>
                    <Panel 
                        header="Búsqueda por Municipio" key="4" 
                        style={{ fontSize:"14px"}}>
                            <div className='insideCollapse'>
                        <Select
                            mode="multiple"
                            allowClear
                            style={{ width: '100%' }}
                            placeholder="Ingrese municipio"
                            defaultValue={[]}
                            onChange={changeSearch}
                        >
                            {
                                uniq(geoDataList.map(d=>d.county)).map((municipio,i)=>
                                    <Option key={i} value={municipio}>{municipio}</Option>)
                            }
                        </Select>
                        <div style={{marginTop:'5px', textAlign:'right'}}>
                            <Button
                                disabled={linearPostalCodes.length===0}
                                onClick={
                                    ()=>{
                                        setPostalCodes(linearPostalCodes.filter(d=>Object.keys(aggPostalCodes).includes(d)))
                                    }
                                }
                            >Seleccionar municipio</Button>
                        </div>
                        </div>
                    </Panel>
                </Collapse>
                <Divider style={{ margin:'8px'}}></Divider>

                <Collapse bordered={false} defaultActiveKey={['1']} ghost>
                    <Panel
                        header="Bookmarks" key="5" 
                        style={{ fontSize:"14px"}}>



                        <div className='insideCollapse' >

                            <div  style={{position:'relative',  width:'100%', height:'100%',}}>
                                <List
                                    //size="small"
                                    header={<div>{bookmarksList.length} Bookmarks</div>}
                                    footer={<div><Input style={{width:'80%'}} value={newBookmarkTitle} onChange={e=>setNewBookmarkTitle(e.target.value)}></Input><Button disabled={newBookmarkTitle===""} style={{width:'20%'}} onClick={()=>{writeBookmark();setNewBookmarkTitle("")}}><PlusCircleFilled /></Button></div>}
                                    bordered={false}
                                    dataSource={bookmarksList}
                                    renderItem={item => 
                                    <List.Item 
                                        actions={[
                                        
                                        <Popover content={
                                        <div>
                                            <div>{
                                            taxonomias.map(taxo=>
                                                <div style={{width:250}}>
                                                <h4>{taxo.title}</h4>
                                                {(item.content[ taxo.code].length>0)?
                                                item.content[taxo.code].map(d=>
                                                <Tag color="blue">{taxo.values.find(x=>x.value===d).label}</Tag>):<Tag color="green">Todos</Tag>
                                                }
                                                <Divider style={{ margin:'4px'}}></Divider>
                                            </div>)}</div>
                                            <div>
                                            {audiencias.map(taxo=>
                                                <div style={{width:250}}>
                                                    <h4>{taxo.title}</h4>
                                                    {(item.content[taxo.code].length>0)?
                                                    item.content[taxo.code].map(d=>
                                                    <Tag style={{margin:'4px'}} color="blue">{taxo.values.find(x=>x.value===d).label}</Tag>):<Tag color="green">Todos</Tag>
                                                    }
                                                    <Divider style={{ margin:'4px'}}></Divider>
                                                </div>)}
                                            </div>
                                            </div>



                                           } title={item.title} trigger="hover">
                                        <InfoCircleFilled />
                                        </Popover>, 

<Popconfirm
placement="right"
title={"Desea eliminar este bookmark?"}
onConfirm={()=>deleteBookmark(item.date)}

cancelText="Cancelar"
okText="Eliminar"
>
<Button key="list-loadmore-more"><DeleteFilled /></Button>
</Popconfirm>
                                        
                                       ]}
                                    >
                                        <List.Item.Meta
                                            title={<a href="#" onClick={()=>{setTaxonomiaSelection(item.content);calculate(item.content);}}>{item.title}</a>}
                                            description={(new Date(item.date)).toLocaleString('en-GB')}
                                            
                                        />
                                    </List.Item>}
                                />

                                {loadingBookmarks?
                                <div style={{position:'absolute',width:'100%',height:'100%', top:0,left:0, backgroundColor:'#181a22e0', margin:'0px', padding:'0px',borderRadius: "0px"}}>
                                    <div
                                        style={{ 
                                        position: 'absolute',
                                        left: '50%',
                                        top: '50%',
                                        transform: 'translate(-50%, -50%)',
                                        }}    
                                    >
                                        <Spin size="large"/>
                                    </div>
                                </div>:null}
                            </div>
                        </div>
                    </Panel>
                </Collapse>
        
            </div>
        </div>
        <div className = "DivScrollParent" >
                <div style={{textAlign:'right',  margin:'8px', padding:'8px'}}>
                    <div>{email}</div>
                    <Button onClick={event => handleLogout(event)}> Cerrar sesión</Button></div>
                    <Collapse bordered={false} defaultActiveKey={['2']}  ghost>
                    <Panel 
                        header="Estilo" key="1" 
                        style={{ fontSize:"14px"}}>
                        <div className='insideCollapse'>
                            <h4>Mapa</h4>
                            <Radio.Group onChange={(e)=>setMapStyle(e.target.value)} value={mapStyle}>
                            <Radio value={MAPBOX_STYLE_1}>Básico</Radio>
                            <Radio value={MAPBOX_STYLE_2}>Satélite</Radio>
                            <Radio value={MAPBOX_STYLE_3}>Calles</Radio>
                            </Radio.Group>
                            <Divider></Divider>
                            <h4>Opacidad</h4>
                            <Slider
                            min={0}
                            max={100}
                            value={opacity}
                            onChange={setOpacity}
                            />
                            <Divider></Divider>
                            <h4>Colores</h4>
                            <input style={{ margin:'4px'}} type="color" defaultValue={color1.current} onChange={(e)=>{color1.current=e.target.value}}></input>
                            <input style={{ margin:'4px'}} type="color" defaultValue={color2.current} onChange={(e)=>{color2.current=e.target.value}}></input>
                            <Button style={{ margin:'4px'}} onClick={()=>setColor([color1.current,color2.current])}>Aplicar Colores</Button>
                    </div>
                    </Panel>
                    <Panel 
                        header="Selectores" key="2" 
                        style={{ fontSize:"14px"}}>
                            <div className='insideCollapse'>
                                
                                <Divider></Divider>

                     <h4>Distribución por horas</h4>
                    <BarChart
                        data={postalCodes.length>0?data.filter(d=>postalCodes.includes(d.codigo_postal)):data}
                        width={240}
                        height={110}
                        update={e=>setTime(e)}
                        updatePlay={(e)=>setPlay(e)}
                        play={play}
                        time={time}

                        stop={()=>clearTimeout(startAnimation.current)}
                        cleared={cleared}
                        updateCleared={e=>setCleared(e)}
                    />
                    <div style={{margin:"8px"}}>
                        <Space>
                        <span style={{ fontSize:"12px"}}>Intervalo: </span>
                        <Select value={intervalPlay} onChange={setIntervalPlay}>
                            <Option key={1} value={250}>0.25 s</Option>
                            <Option key={2} value={500}>0.5 s</Option>
                            <Option key={3} value={1000}>1 s</Option>
                        </Select>
                        
                    <Button
                    disabled={time[0]===0 && time[1]===23}
                    onClick={()=>{play?setPlay(false):setPlay(true)
                    }}
                    >{play?"Stop":"Play"}</Button>
                    </Space>
                    </div>
                    <Divider></Divider>
                    <h4>Distribución por código postal</h4>
                    <Histogram
                        data={data.filter(d=> d.hora>=hour[time[0]] && d.hora<=hour[time[1]])}
                        time={time}
                        width={240}
                        height={130}
                        update={setPostalCodes}
                    />
                    </div>
                    </Panel>

                    
                    <Panel 
                        header="Análisis de Shares" key="3" 
                        style={{ fontSize:"14px"}}>
                            <div className='insideCollapse'>
                            
                            <Button
                                onClick={() =>{
                                    Analytics.record({
                                        name: 'powerbi',
                                        attributes: { user: email, address:group + '_participacion' }
                                    });
                                    window.open('/'+ group +'_participacion')
                                }
                                } >Participación</Button>
                                <br></br>
                               
                            <Button style={{}} onClick={() =>{
                                Analytics.record({
                                    name: 'powerbi',
                                    attributes: { user: email, address:group +'_comparativo' }
                                });
                                window.open('/'+ group + '_comparativo')
                            }
                             
                            } >Comparación</Button>
<br></br>
                            <Button loading={loadingPDF} onClick={() =>{
                                Analytics.record({
                                    name: 'exportPDF',
                                    attributes: { user: email, address:group }
                                });
                                
                                exportPDF()
                                                        }
                             
                            } >Exportar reporte en PDF</Button>

                            </div>
                    </Panel>
                  </Collapse>
                    
                    <Divider style={{ margin:'8px'}}></Divider>
                    <div style={{ margin:'8px', padding:'8px'}}>
                        <h4>Leyenda</h4>
                        <Legend
                            maxValue={maxValue}
                            color={color}
                            width={240}
                            height={40}
                        />
                    </div>
        </div>

        <div style={{position:'absolute',color:"#FFFA", minHeight:'70px', width:'380px', backgroundColor:'#181a22E0', margin:'5px',left:'270px'}}>
            <div style={{  paddingTop:'12px', paddingLeft:'12px', paddingRight:"12px"}}>
                <span style={{fontSize:"10px" }}>Promedio de Suscriptores (selección/total)</span>
                <div style={{fontSize:"32px", textAlign:"center", color:"#FFF", fontWeight:700 }}>
                {formatting(kpis.selection_selection_hora,3)}
                    <span style={{fontSize:"24px"}}> / {formatting(kpis.total_total_hora,3)}</span>
                    <span style={{ color:"#AAA", fontSize:"18px", fontWeight:500}}>  ({(d3.format('+.2f')(kpis.share_hora))}%)</span>
                </div>
                <div style={{fontSize:"12px", textAlign:"center", color:"#FFF", fontWeight:500 }}>
                Share del total: {formatting(100*(kpis.selection/24)/kpis.total_total_hora,3)}%
                      </div>

            </div>
            <Collapse  style={{  padding:'0px'}} bordered={false} defaultActiveKey={[]} ghost>
                <Panel 
                    header="Más" key="1" 
                    style={{ fontSize:"12px"}}>
                        <div className='insideCollapse'>
                            <div style={{fontSize:"12px",fontWeight:400}}>Por hora y Código Postal</div>
                            <span style={{fontSize:"24px",fontWeight:700}}> {formatting(kpis.selection_selection_hora_selection_cp,3)}</span>
                            <span style={{fontSize:"16px",fontWeight:700}}> / {formatting(kpis.total_total_hora_total_cp,3)}</span>
                            <span style={{fontSize:"14px",color:"#AAA", fontWeight:500}}> ({(d3.format('+.2f')(kpis.share_hora_cp))}%)</span>
                        </div>


                </Panel>
            </Collapse>
        </div>

        <div style={{position:'absolute',color:"#FFFA", minHeight:'105px', width:'180px', backgroundColor:'#181a22E0', margin:'5px', right:'270px'}}>
            <div style={{  paddingTop:'12px', paddingLeft:'12px', paddingRight:"12px"}}>
                <span style={{fontSize:"10px" }}>Horas</span>
         
                <div style={{fontSize:"28px", textAlign:"center", color:"#FFF", fontWeight:700 }}>
                    {hour[time[0]]} - {hour[time[1]]}
                </div>
                {!(time[1]===23 && time[0]===0)?
                <div style={{ textAlign:"center" }}>
                <Button 
                onClick={()=>{setTime([0,23]); setCleared(true)
                }}  size="small" shape="round">Limpiar selección <CloseCircleFilled /></Button>
                </div>
                :null}
            </div>
        </div>

        <div style={{
           
            position:'absolute',color:"#FFFA", minHeight:'105px', width:'180px', backgroundColor:'#181a22E0', margin:'5px', right:'455px'}}>
            <div style={{  transition: '4s', paddingTop:'12px', paddingLeft:'12px', paddingRight:"12px"}}>
                <span style={{fontSize:"10px" }}>Códigos Postales</span>

                <a
                    onClick={()=>
                        Modal.info({
                        
                        title: 'Códigos postales seleccionados',
                        width:550,
                        
                        content: (

                            postalCodes.length>0?
                            <Table columns={[
                                {
                                    title:'Código Postal',
                                    key:'code',
                                    dataIndex:'code'
                                },
                                {
                                    title:'Promedio',
                                    key:'mean',
                                    dataIndex:'mean',
                                    render:(e) => formatting(e,3)
                                },
                                {
                                    title:'Máximo',
                                    key:'max',
                                    dataIndex:'max',
                                    render:(e) => formatting(e,3)
                                },
                                {
                                    title:'Mínimo',
                                    key:'min',
                                    dataIndex:'min',
                                    render:(e) => formatting(e,3)
                                },
                                
                            ]}
                            dataSource={postalCodes.map((e,i)=>{
                                return({
                                    key : i,
                                    code: e,
                                    ...aggPostalCodes[e]
                                })
                            })}
                            size="small"
                            pagination={ false } scroll={{ y: 240 }}
                            />:<div>Seleccione códigos postales primero</div>
                        ),
                        onOk() {},
                        })}
                >
                <div style={{fontSize:"28px", textAlign:"center", color:"#FFF", fontWeight:700 }}>
                    {kpis.selection_cp} / {kpis.total_cp}
                </div></a>
                
                {postalCodes.length>0?
                <div style={{ textAlign:"center" }}>
                <Button onClick={()=>setPostalCodes([])}  size="small" shape="round">Limpiar selección <CloseCircleFilled /></Button>
                </div>
                :null}
            </div>
        </div>
        {querying?
        <div
            style={{position:'absolute',  width:'100%', height:'100%', backgroundColor:'#181a22E0', margin:'0px', padding:'0px',borderRadius: "0px"}}>
            <div
                style={{ 
                position: 'absolute',
                left: '50%',
                top: '50%',
                transform: 'translate(-50%, -50%)',
                }}    
            >
                <Spin size="large"/>
            </div>
        </div>
        :null}
    </div>);
}

export default DashboardContainer;