/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:5f09aa78-2149-46e2-9377-fdb62bb385bc",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_zVIxMo2Sa",
    "aws_user_pools_web_client_id": "1jn648s8pg36b071hbrq4940j6",
    "oauth": {},
    "aws_mobile_analytics_app_id": "488b5b52224946e885cc0b6d9fc2a924",
    "aws_mobile_analytics_app_region": "us-east-1",
    "aws_content_delivery_bucket": "telcelgeo-20201009144342-hostingbucket-dev",
    "aws_content_delivery_bucket_region": "us-east-1",
    "aws_content_delivery_url": "https://d3g8g7h6msc29i.cloudfront.net"
};


export default awsmobile;
