import React, {useState}  from 'react';
import { Form, Input, Button, Alert, notification } from 'antd';
import { Auth } from 'aws-amplify';
import { AUTH_USER_TOKEN_KEY } from '../../Utils/constants';

const layout = {
    labelCol: {
      span: 8,
    },
    wrapperCol: {
      span: 16,
    },
  };
const tailLayout = {
    wrapperCol: {
      offset: 8,
      span: 16,
    },
};

const LoginContainer = (props) => {
    const [querying , setQuerying]= useState(false)
    const onFinish = (values) => {
        setQuerying(true)
        let { username, password } = values;

        Auth.signIn(username, password).then(user => {

            const { history, location } = props;
            if(user.challengeName === "NEW_PASSWORD_REQUIRED"){
                Auth.completeNewPassword(user, password, '').then(() => {
                    localStorage.setItem(AUTH_USER_TOKEN_KEY, user.signInUserSession.accessToken.jwtToken);

                    notification.success({
                        message: 'Bienvenido a MIA',
                        description: '',
                        placement: 'topLeft',
                        duration: 3
                      })

                    history.push("/dashboard")
                    setQuerying(false)
                })
            }
            else{
                localStorage.setItem(AUTH_USER_TOKEN_KEY, user.signInUserSession.accessToken.jwtToken);
                history.push("/dashboard")
                setQuerying(false)
            }

        })
        .catch(error=>{
            if (error.code==='UserNotFoundException'){
                notification.error({
                    message: 'Error',
                    description: 'El usuario no existe',
                    placement: 'topLeft',
                    duration: 3
                  })

            }
            else if (error.code==='NotAuthorizedException'){
                notification.error({
                    message: 'Error',
                    description: 'El usuario o contraseña es incorrecto',
                    placement: 'topLeft',
                    duration: 3
                  })

            }
            setQuerying(false)
        })


    };
        
    
    const onFinishFailed = (errorInfo) => {

    };
    
    return (
        <div style={{position:'fixed', backgroundColor:'#272b3d', width:'100%',height:'100%'}}>
            <div
            style={{ backgroundColor:'#181a22E0',
            position: 'absolute',
            left: '50%',
            top: '50%',
            transform: 'translate(-50%, -50%)',
            minWidth:'350px',  padding:'32px'}}
            >
            
            <h1>MIA</h1>
            <Form
              
                {...layout}
                name="basic"
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
            >

                    <Form.Item
            
                        label="Usuario"
                        name="username"
                        rules={[
                        {
                            required: true,
                            message: 'Por favor ingrese usuario!',
                        },
                        ]}
                    >
                        <Input />
                    </Form.Item>
            
                    <Form.Item
                        label="Contraseña"
                        name="password"
                        rules={[
                        {
                            required: true,
                            message: 'Por favor ingrese su contraseña!',
                        },
                        ]}
                    >
                    <Input.Password />
                </Form.Item>
        
                <Form.Item {...tailLayout}>
                    <Button htmlType="submit" loading={querying}>
                        Iniciar sesión
                    </Button>
                </Form.Item>
            </Form>
        </div>
        </div>
      );
};
export default LoginContainer