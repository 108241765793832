import * as React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

/** Container components */
import DashboardContainer from '../Containers/DashboardContainer';
import LoginContainer from '../Containers/LoginContainer';
import {TableroContainer} from '../Containers/TableroContainer';

/** private route component */
import PrivateRoute from './PrivateRoute';

const AppRouter = () => {
    return (
      <Router>
          <Switch>
            <PrivateRoute exact={true} path="/" component={DashboardContainer} />
            <Route exact={true} path="/dashboard" component={DashboardContainer} />
            <Route exact={true} path="/login" component={LoginContainer} />
            <Route  exact={true} component={TableroContainer}/>
          </Switch>
      </Router>
    );
}

export default AppRouter;
