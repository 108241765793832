import * as React from 'react';
import './App.less';

/** Components */
import AppRouter from './Navigation/Routes';

const App = ()=>{
  return <AppRouter />;
}

export default App;


