import React, { useEffect, useRef } from "react";
import * as d3 from "d3";
import {formatting} from "../../Utils/Helpers"

const margin={left:40,right:40,top:5,bottom:40}

const Histogram = props => {    
    const d3Container = useRef(null);
    useEffect(
        () => {
            let data = props.data

            let result = [];
            data.reduce((res, value)=> {
                if (!res[value.codigo_postal]) {
                res[value.codigo_postal] = { codigo_postal: value.codigo_postal, nsubs: 0 };
                result.push(res[value.codigo_postal])
                }
                res[value.codigo_postal].nsubs += value.nsubs/(props.time[1]-props.time[0]+1);
                return res;
            }, {});



            result=result.map(d=>{return({codigo_postal:d.codigo_postal, nsubs:d.nsubs})})

            const max = d3.max(result, d => d.nsubs);
            const min = 0;
            const x = d3.scaleLinear()
                .domain([min, max])
                .range([0, props.width-25]);

            const histogram = d3.histogram()
                .value(d=> d.nsubs)
                .domain(x.domain())
                .thresholds(x.ticks(20));

            const bins = histogram(result);

            const xDomain=bins.map((d,i)=>i)
            
            const xScale = d3.scaleBand()
                .domain( xDomain )
                .range([ 0, props.width - margin.right ])
                .padding(0);
            const yMax = d3.max(bins, d=> d.length)
            const yScale = d3.scaleLinear()
                .domain([ 0, yMax ])
                .range([ props.height - margin.bottom, margin.top ])

            const yScaleInversed = d3.scaleLinear().domain([0,yMax]).range([ props.height - margin.bottom, margin.top ])

            const brushEnd = (event) => {
                if (!event.selection) {
                    props.update([]);
                }
                else{
                    const [x1, x2] = event.selection;
                    const range = [Math.floor(x1/xScale.bandwidth()),Math.floor((x2-1)/xScale.bandwidth())];
                    const selection = bins.filter((d,i)=> i<=range[1] && i>=range[0]).flat()
                    //const selectionFlat = selection.reduce((acc,curr) => acc.concat(curr),[])
                    const selectionFlatCodes=selection.map(e=>e.codigo_postal)
                    props.update(selectionFlatCodes)
                }
            };
            const svg = d3.select(d3Container.current);
            const brush = d3.brushX()
                .extent([[0,margin.top],[props.width-margin.right, props.height-margin.bottom]
                ])
            .on("brush end", brushEnd);

            //svg.select(".brush").remove()
            svg.append("g").attr("class","brush").attr("transform","translate("+margin.left+",0)")

            svg.select(".brush").call(brush);

            svg.select(".bars")
            .attr("fill",'#9EBAD599')
            .selectAll("rect")
            .data(bins)
            .join("rect")
                .attr("x", (d,i) => xScale(i))
                .attr("width", xScale.bandwidth())
                .attr("y", d => yScale(d.length))
                .attr("height", d => yScale(0) - yScale(d.length))

        let xAxisFunction = d3.axisBottom()
        .scale(xScale)
        .ticks(1, 's').tickSizeOuter(0);


        svg.select(".axis")
        .call(xAxisFunction)
        .selectAll("text")	
            .text(d=>d%Math.floor(bins.length/5)===0?formatting((bins[d].x1),2):"")
            .style("text-anchor", "end")
            .style("opacity", 0.3)
            .attr("dx", "-.8em")
            .attr("dy", ".15em")
            .attr("transform", "rotate(-90)");

            let yAxisFunction = d3.axisLeft()
            .scale(yScaleInversed)
            .ticks(4, 's');
        
            svg.select(".axisLeft")
            .call(yAxisFunction)
            .selectAll("text")
                .style("text-anchor", "end")
                .style("opacity", 0.3)
                .attr("dx", "-.1em")
                .attr("dy", ".15em");
        },
        [props.height, props.width, props.data, props.time]
        );

    return (
        <svg 
            width={props.width}
            height={props.height}
            ref={d3Container} >
                <g className="bars" transform={"translate("+margin.left+",0)"}></g>
                <g className="labels"></g>
                <g className="values"></g>
                <g className="axis" transform={"translate("+margin.left+","+ (props.height - margin.bottom)+")"}></g>
                <g className="axisLeft" transform={"translate("+margin.left+",0)"}></g>
                <g className="brush" transform={"translate("+margin.left+",0)"} />
                <text fill={'#9EBAD599'} textAnchor="middle" transform={"rotate(-90) translate(-58,10)"} fontSize={9}>Códigos Postales</text>
                <text fill={'#9EBAD599'} textAnchor="middle" fontSize={9}x={(margin.left + props.width)/2} y={props.height}>Promedio de Subscriptores</text>
        </svg>
    )
};

export default Histogram;