import React, { useState, useRef, useEffect } from 'react';
import { validateToken } from '../../Utils/Helpers';
import { AUTH_USER_TOKEN_KEY, URL1,URL2 } from '../../Utils/constants';
import { models } from 'powerbi-client';
import { PowerBIEmbed } from 'powerbi-client-react';
import { useLocation } from 'react-router-dom';

import  './index.css';

export const TableroContainer = (props) => {


    console.log(props)
    const pathname = useLocation().pathname.replace("/","")
    const [reportLoadConfig,setReportLoadConfig] = useState({
		type: 'report',   // Supported types: report, dashboard, tile, visual and qna
		id: undefined, 
		embedUrl: undefined,
		accessToken: undefined,    // Keep as empty string, null or undefined
		tokenType: models.TokenType.Embed
	})

    useEffect(()=>{
        const checkUserAuth = validateToken(localStorage.getItem(AUTH_USER_TOKEN_KEY));
        if(!checkUserAuth){             
            const {history} = props;
            history.push("/login")
        }
    
        const {client_id, username} = checkUserAuth
        const bearer = 'Bearer ' + localStorage.getItem('CognitoIdentityServiceProvider.' + client_id + '.' + username + '.idToken')
        const callBody ='/apiv2/powerbi_report?report_name=' + pathname
        
        fetch(callBody, {
            method: 'GET',
            credentials: 'include',
            headers: {
                'Authorization': bearer,
                'Connection': 'keep-alive',
            },
        })
        .then(res => res.json())
        .then(result =>{
            setReportLoadConfig({
                type: "report",
                id: result.report_id,
                tokenType: models.TokenType.Embed,
                accessToken: result.token,
                embedUrl: result.report.embedUrl,
            })
        })
        .catch(error=>{
            console.log(error)
        })
    },[])

    return (
    <PowerBIEmbed
        embedConfig = {reportLoadConfig}
        cssClassName = { "report-style-class" }
    />
    );
  };
